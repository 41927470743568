var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2AY6KFpVHL4CGCfxt9441"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { CaptureConsole } from '@sentry/integrations'

// MEMO: sentry dsn は秘匿情報ではない
const SENTRY_DSN =
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  'https://60fa0dde11a5bad3c60669d2d6fd6212@o4506301054189568.ingest.sentry.io/4506301061922816'

const hasSentryAuthToken = !!process.env.NEXT_PUBLIC_SENTRY_DSN
if (hasSentryAuthToken) {
  Sentry.init({
    dsn: SENTRY_DSN,
    // 各環境ごとに送られる
    environment: process.env.NEXT_PUBLIC_APP_ENV || 'dev1',
    integrations: [
      new CaptureConsole({
        levels: ['error']
      })
    ],
    // ignoreErrors: ['gtag/js'],
    // error sample rate
    sampleRate: 1,
    tracesSampleRate: 1.0
  })
} else {
  console.warn('sentry の初期化に失敗しました。SENTRY_SECURITY_TOKEN_CLIENT がありません')
}
